import { useStaticQuery, graphql } from 'gatsby';

const usePressAccreditationPage = () => {
    const { sanityPagePressAccreditation: pressAccreditationPage } = useStaticQuery(graphql`
        query {
            sanityPagePressAccreditation {
                accreditationDescription {
                    en {
                        _rawContent
                    }
                    se {
                        _rawContent
                    }
                }
                accreditationForms {
                    _key
                    title {
                        en
                        se
                    }
                    description {
                        en {
                            _rawContent
                        }
                        se {
                            _rawContent
                        }
                    }
                    team {
                        id
                    }
                    showForm
                }
                accreditationFormsGdpr {
                    en
                    se
                }
                accreditationFormsLegals {
                    en {
                        _rawContent
                    }
                    se {
                        _rawContent
                    }
                }
                accreditationFormsSuccessMessage {
                    en {
                        _rawContent
                    }
                    se {
                        _rawContent
                    }
                }
            }
        }
    `);

    return pressAccreditationPage;
};

export default usePressAccreditationPage;
