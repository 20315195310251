import React, { useContext, useState } from 'react';

import * as style from 'styles/components/press/accreditation.module.scss';
import useAllMatches from 'data/queries/useAllMatches';
import { LangContext } from 'context/LangContext';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import TextEditorRender from 'components/utils/TextEditorRender';

const AccreditationForm = ({
    accreditationName,
    formGdprMessage,
    formSuccessMessage,
    formLegals,
    formTeam,
}) => {
    const { lang } = useContext(LangContext);

    const [showForm, setShowForm] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formError, setFormError] = useState(false);

    const [accreditationNumber, setAccreditationNumber] = useState(1);

    const handleAccreditationNumber = (evt) => {
        setAccreditationNumber(parseInt(evt.target.value, 10));
    };

    const allMatches = useAllMatches();
    let filteredMatches = new Array(0);

    if (formTeam) {
        const upcomingMatches = () =>
            allMatches
                .filter((match) => match.matchStatus !== 'finished')
                .filter((item) => item.team.id === formTeam.id);

        filteredMatches = upcomingMatches();
    }

    const buildDate = (matchDate) => {
        const date = new Date(matchDate);
        const formattedDate = date.toLocaleDateString('sv-SE', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
        const formattedHour = date.toLocaleTimeString('sv-SE', {
            timeStyle: 'short',
        });
        return `${formattedDate} ${formattedHour}`;
    }

    const postUserData = (data) => {
        const requestUrl = process.env.GATSBY_ACCREDITATION_FORM_ENDPOINT;
        const requestParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        };

        fetch(requestUrl, requestParams)
            .then(() => {
                setShowForm(false);
                setFormSubmitted(true);
            })
            .catch((error) => {
                console.warn('Request failed', error);

                setShowForm(false);
                setFormError(true);
            });
    };

    const handleFormSubmit = (evt) => {
        evt.preventDefault();

        const formData = new FormData(evt.target);

        const accreditationGlobalData = {};
        const accreditations = {
            accreditationFirstName: [],
            accreditationLastName: [],
            accreditationEmail: [],
            accreditationPhone: [],
            accreditationCategory: [],
            accreditationCategoryOther: [],
            accreditationPressCard: [],
        };

        formData.forEach((value, key) => {
            if (key === 'consent') {
                value = value === 'on';
            }

            // push all accreditations field into an array
            if (accreditations[key.slice(0, -1)]) {
                accreditations[key.slice(0, -1)].push(value);
            } else {
                accreditationGlobalData[key] = value;
            }
        });

        postUserData({ ...accreditationGlobalData, ...accreditations });
    };

    return (
        <div>
            {/* Open / close the form */}
            <button className={`${style.form__showForm} cta-yellow`} type="button"
                    onClick={() => setShowForm(!showForm)}>
                {showForm ? <FormattedMessage id="form.back" /> : <FormattedMessage id="form.apply" />}
            </button>

            <div>
                {/* When the form is submitted display a success message */}
                {formSuccessMessage && formSuccessMessage[lang.translationKey] && formSubmitted && (
                    <div className={style.form__success}>
                        <TextEditorRender content={formSuccessMessage[lang.translationKey]._rawContent} />
                    </div>
                )}

                {/* When the form request failed display an error message */}
                {formError && (
                    <div className={style.form__error}>
                        <FormattedMessage id="partners.formError" />
                    </div>
                )}

                {/* Display form */}
                {showForm && (
                    <form method="POST" onSubmit={handleFormSubmit} className={style.form}>
                        <input type="hidden" name="accreditationName" value={accreditationName} />

                        {filteredMatches.length > 0 && (
                            <div className="form-group">
                                <label htmlFor="accreditationMatch">
                                    Match
                                    <em>*</em>
                                </label>
                                <select name="accreditationMatch" id="accreditationMatch">
                                {filteredMatches.slice(0,2).map((item, index) => (
                                    <option key={index} value={`${item.local.title} - ${item.external.title}, ${buildDate(item.startEventDate)}`}>
                                        {item.local.title} - {item.external.title}, {buildDate(item.startEventDate)}
                                    </option>
                                ))}
                                </select>
                            </div>
                        )}

                        <div className="form-group">
                            <label htmlFor="accreditationNumber">
                                <FormattedMessage id="press.form.quantity" />
                                <em>*</em>
                            </label>
                            <select name="accreditationNumber" id="accreditationNumber"
                                    value={accreditationNumber}
                                    onChange={handleAccreditationNumber}
                            >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </div>

                        <h3 className="text-md bold form-group full">
                            <FormattedMessage id="press.form.contact" />
                        </h3>

                        <div className="form-group">
                            <label htmlFor="firstName"><FormattedMessage id="form.firstName" /><em>*</em></label>
                            <input type="text" id="firstName" name="firstName" autoComplete="firstname" required
                                   placeholder={getFormattedMessage('form.firstName', lang)} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="lastName"><FormattedMessage id="form.lastName" /><em>*</em></label>
                            <input type="text" id="lastName" name="lastName" autoComplete="lastname" required
                                   placeholder={getFormattedMessage('form.lastName', lang)} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="company"><FormattedMessage id="form.company" /></label>
                            <input type="text" name="company" id="company" autoComplete="organization"
                                   placeholder={getFormattedMessage('form.company', lang)} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="email"><FormattedMessage id="form.email" /><em>*</em></label>
                            <input type="email" name="email" id="email" autoComplete="email" required
                                   placeholder={getFormattedMessage('form.email', lang)}
                                   pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" />
                        </div>

                        <div className="form-group">
                            <label htmlFor="phoneProfessional"><FormattedMessage id="form.phoneProfessional" /></label>
                            <input type="tel" name="phoneProfessional" id="phoneProfessional" autoComplete="tel"
                                   placeholder={getFormattedMessage('form.phoneProfessional', lang)} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="phone"><FormattedMessage id="form.phoneMobile" /></label>
                            <input type="tel" name="phone" id="phone" autoComplete="tel"
                                   placeholder={getFormattedMessage('form.phoneMobile', lang)} />
                        </div>

                        {Array.from({ length: accreditationNumber }, (_, index) => {
                            index += 1;

                            return (
                                <div key={index} className={style.form}>
                                    <h3 className="text-md bold form-group full">
                                        <FormattedMessage id="press.form.accreditation" /> {index}
                                    </h3>

                                    <div className="form-group">
                                        <label htmlFor={`accreditationFirstName${index}`}>
                                            <FormattedMessage id="form.firstName" /><em>*</em>
                                        </label>
                                        <input type="text"
                                               id={`accreditationFirstName${index}`}
                                               name={`accreditationFirstName${index}`}
                                               autoComplete="firstName" required
                                               placeholder={getFormattedMessage('form.firstName', lang)} />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor={`accreditationLastName${index}`}>
                                            <FormattedMessage id="form.lastName" /><em>*</em>
                                        </label>
                                        <input type="text"
                                               id={`accreditationLastName${index}`}
                                               name={`accreditationLastName${index}`}
                                               autoComplete="lastName" required
                                               placeholder={getFormattedMessage('form.lastName', lang)} />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor={`accreditationEmail${index}`}>
                                            <FormattedMessage id="form.email" /><em>*</em>
                                        </label>
                                        <input type="text"
                                               id={`accreditationEmail${index}`}
                                               name={`accreditationEmail${index}`}
                                               autoComplete="email" required
                                               placeholder={getFormattedMessage('form.email', lang)} />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor={`accreditationPhone${index}`}>
                                            <FormattedMessage id="form.phone" />
                                        </label>
                                        <input type="text"
                                               id={`accreditationPhone${index}`}
                                               name={`accreditationPhone${index}`}
                                               autoComplete="phone"
                                               placeholder={getFormattedMessage('form.phone', lang)} />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor={`accreditationCategory${index}`}>
                                            <FormattedMessage id="press.form.category" />
                                            <em>*</em>
                                        </label>
                                        <select name={`accreditationCategory${index}`}
                                                id={`accreditationCategory${index}`}
                                                required
                                                defaultValue=""
                                        >
                                            <option value="" disabled hidden>
                                                {getFormattedMessage('press.form.category', lang)}
                                            </option>
                                            <option value="press">
                                                {getFormattedMessage('press.form.press', lang)}
                                            </option>
                                            <option value="foto">
                                                {getFormattedMessage('press.form.photo', lang)}
                                            </option>
                                            <option value="radio">
                                                {getFormattedMessage('press.form.radio', lang)}
                                            </option>
                                            <option value="tv">
                                                {getFormattedMessage('press.form.tv', lang)}
                                            </option>
                                            <option value="webb">
                                                {getFormattedMessage('press.form.web', lang)}
                                            </option>
                                            <option value="övrigt">
                                                {getFormattedMessage('press.form.other', lang)}
                                            </option>
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor={`accreditationCategoryOther${index}`}>
                                            <FormattedMessage id="press.form.other" />
                                        </label>
                                        <input type="text"
                                               id={`accreditationCategoryOther${index}`}
                                               name={`accreditationCategoryOther${index}`}
                                               autoComplete="name"
                                               placeholder={getFormattedMessage('press.form.other', lang)} />
                                    </div>

                                    <div className="form-group full radio">
                                        <p className="text-sm bold label">
                                            <FormattedMessage id="press.form.pressCard" />
                                            <em>*</em>
                                        </p>

                                        <div className="group-radio">
                                            <input type="radio"
                                                   name={`accreditationPressCard${index}`}
                                                   id={`accreditationPressCard-AIPS${index}`}
                                                   value="AIPS-kort"
                                                   autoComplete="name" required
                                            />
                                            <label htmlFor={`accreditationPressCard-AIPS${index}`}>
                                                <FormattedMessage id="press.form.AIPSCard" />
                                            </label>
                                        </div>

                                        <div className="group-radio">
                                            <input type="radio"
                                                   name={`accreditationPressCard${index}`}
                                                   id={`accreditationPressCard-annat${index}`}
                                                   value="Annat presskort"
                                                   required
                                            />
                                            <label htmlFor={`accreditationPressCard-annat${index}`}>
                                                <FormattedMessage id="press.form.otherCard" />
                                            </label>
                                        </div>

                                        <div className="group-radio">
                                            <input type="radio"
                                                   name={`accreditationPressCard${index}`}
                                                   id={`accreditationPressCard-saknass${index}`}
                                                   value="kort saknas"
                                                   required
                                            />
                                            <label htmlFor={`accreditationPressCard-saknass${index}`}>
                                                <FormattedMessage id="press.form.missingCard" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        {formGdprMessage && formGdprMessage[lang.translationKey] && (
                            <div className="form-group checkbox">
                                <input type="checkbox" name="consent" id="consent" required />
                                <label htmlFor="consent">
                                    <p className="richtext inline">{formGdprMessage[lang.translationKey]}</p>
                                    <em>*</em>
                                </label>
                            </div>
                        )}

                        <p className="form-required">
                            <em>*</em>
                            <FormattedMessage id="form.requiredFields" />
                        </p>

                        <div className="form-submit">
                            <button className={`${style.form__submit} cta-yellow`} type="submit" aria-label={getFormattedMessage('form.send', lang)}>
                                <FormattedMessage id="form.send" />
                            </button>

                            {formLegals && formLegals[lang.translationKey] && (
                                <TextEditorRender content={formLegals[lang.translationKey]._rawContent} />
                            )}
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default AccreditationForm;
