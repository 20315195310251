import { useStaticQuery, graphql } from 'gatsby';

const useAllMatches = () => {
    const {
        allSanityMatch: { nodes: matches },
    } = useStaticQuery(graphql`
        query allMatches {
            allSanityMatch(
                filter: { slug: { current: { ne: null } } }
                sort: { order: ASC, fields: startEventDate }
            ) {
                nodes {
                    _id
                    slug {
                        current
                    }
                    headerBanner {
                        alt
                        image {
                            asset {
                                _id
                                url
                            }
                        }
                    }
                    matchStatus
                    startEventDate
                    attendance
                    hideSplash
                    location
                    ticketsSellStartTime
                    ticketsSoldOut
                    ticketsSold
                    ticketsUrl
                    ticketsId
                    freeAdmission
                    isCustomText
                    customText
                    wyscoutId
                    competition {
                        title
                    }
                    season {
                        _id
                        title
                    }
                    liveStream {
                        url
                    }
                    team {
                        _id
                        id
                        title {
                            en
                            se
                        }
                    }
                    teamExternal {
                        _id
                        id
                        title {
                            en
                            se
                        }
                    }
                    local {
                        image {
                            ...ImageWithPreview
                        }
                        isHammarby
                        title
                    }
                    external {
                        image {
                            ...ImageWithPreview
                        }
                        isHammarby
                        title
                    }
                    externalScore
                    localScore
                    primarySponsor {
                        logoWhite {
                            ...ImageWithPreview
                        }
                        url
                        title
                    }
                    secondarySponsor {
                        logoWhite {
                            ...ImageWithPreview
                        }
                        url
                        title
                    }
                    localStatistic {
                        corners
                        fouls
                        offsides
                        possession
                        redCard
                        saves
                        shots
                        yellowCard
                    }
                    externalStatistic {
                        corners
                        fouls
                        offsides
                        possession
                        redCard
                        saves
                        shots
                        yellowCard
                    }
                    externalLineupComposition
                    localLineupComposition
                    localLineup {
                        player {
                            headerImage {
                                image {
                                    asset {
                                        _id
                                        altText
                                    }
                                    crop {
                                        bottom
                                        left
                                        right
                                        top
                                    }
                                    hotspot {
                                        height
                                        width
                                        x
                                        y
                                    }
                                }
                            }
                            image {
                                alt
                                image {
                                    asset {
                                        _id
                                        altText
                                    }
                                    crop {
                                        bottom
                                        left
                                        right
                                        top
                                    }
                                    hotspot {
                                        height
                                        width
                                        x
                                        y
                                    }
                                }
                            }
                            lastName
                            number
                        }
                        position
                        fieldPosition
                    }
                    externalLineup {
                        player {
                            headerImage {
                                image {
                                    asset {
                                        _id
                                        altText
                                    }
                                    crop {
                                        bottom
                                        left
                                        right
                                        top
                                    }
                                    hotspot {
                                        height
                                        width
                                        x
                                        y
                                    }
                                }
                            }
                            image {
                                alt
                                image {
                                    asset {
                                        _id
                                        altText
                                        url
                                    }
                                    crop {
                                        bottom
                                        left
                                        right
                                        top
                                    }
                                    hotspot {
                                        height
                                        width
                                        x
                                        y
                                    }
                                }
                            }
                            lastName
                            number
                        }
                        position
                        fieldPosition
                    }
                }
            }
        }
    `);
    return matches;
};

export default useAllMatches;
