import React, { useContext } from 'react';

import * as style from 'styles/components/press/accreditation.module.scss';
import { LangContext } from 'context/LangContext';
import usePressAccreditationPage from 'data/queries/usePressAccreditationPage';
import AccreditationForm from 'components/press/AccreditationForm';
import FormattedMessage from 'components/utils/FormattedMessage';
import TextEditorRender from 'components/utils/TextEditorRender';

const PressAccreditation = () => {
    const { lang } = useContext(LangContext);
    const pressAccreditationPage = usePressAccreditationPage();

    return (
        <div className={`${style.container} wrapper`}>
            <h1 className="title-lg bold">
                <FormattedMessage id="press.accreditation" />
            </h1>
            {pressAccreditationPage.accreditationDescription && (
                <div className={style.accreditation__description}>
                    <TextEditorRender content={pressAccreditationPage.accreditationDescription[lang.translationKey]._rawContent} />
                </div>
            )}

            {pressAccreditationPage.accreditationForms.map(form => (
                <div key={form._key} className={style.accreditation__container}>
                    <div className={style.accreditation__info}>
                        <h2 className="text-lg bold">{form.title[lang.translationKey]}</h2>
                        {form.description[lang.translationKey] && (
                            <TextEditorRender content={form.description[lang.translationKey]._rawContent} />
                        )}
                    </div>
                    {form.showForm && (
                        <AccreditationForm
                            accreditationName={form.title.se}
                            formGdprMessage={pressAccreditationPage.accreditationFormsGdpr}
                            formLegals={pressAccreditationPage.accreditationFormsLegals}
                            formSuccessMessage={pressAccreditationPage.accreditationFormsSuccessMessage}
                            formTeam={form.team}
                        />
                    )}

                    <hr/>
                </div>
            ))}
        </div>
    );
};

export default PressAccreditation;
